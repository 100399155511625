export const LOCAL_STORAGE = {
  user: "user",
  project: "project",
  block: "block",
  level: "level",
  apartment: "apartment",
  item: "item"
}

export const EMPTY_OBJECT_STRING = '{}'

export const STATUS_VALUE = [
  'NOT_DONE',
  'DONE',
  'REJECTED',
  'FIXED',
  'DONE_AND_VERIFIED',
  'CONTRACTOR_APPROVED',
  'CONTRACTOR_REJECTED'
];
export const STATUS = {
  notDone: STATUS_VALUE[0],
  done: STATUS_VALUE[1],
  rejected: STATUS_VALUE[2],
  fixed: STATUS_VALUE[3],
  doneAndVerified: STATUS_VALUE[4],
  contractorApproved: STATUS_VALUE[5],
  contractorRejected: STATUS_VALUE[6]
}

export const ROLE = {
  manager: 'Manager',
  user: 'User',
  contractor: 'Contractor',
  site_foreman: 'site foreman'.toUpperCase()
}